$(document).ready(function() {
    $( ".solekreative-logo__img" ).mgGlitch({
        destroy : false, 
        glitch: true, 
        scale: true, 
        blend : true, 
        blendModeType : 'multiply',
        // set min time for glitch 1 elem
        glitch1TimeMin : 200, 
        // set max time for glitch 1 elem
        glitch1TimeMax : 400,
        // set min time for glitch 2 elem
        glitch2TimeMin : 10, 
        // set max time for glitch 2 elem
        glitch2TimeMax : 100, 
    });

    
});